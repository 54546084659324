import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useContext } from "react";

import Logo from "../images/logo.svg";
import LogoLight from "../images/logo-light.svg";
// import LogoEvent from "../images/indonesia-76.svg";
import Button from "../components/button";
import Link from "../components/link";

import { ThemeContext } from "../context/themeContext";

function Header() {
    const [isExpanded, toggleExpansion] = useState(false);

    const { theme } = useContext(ThemeContext);

    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
            contentfulMenuTop {
                megaMenu {
                    title
                    content {
                        icon
                        text
                        title
                        url
                    }
                }
                menu {
                    title
                    route
                    isInternal
                }
                cta {
                    title
                    route
                    type
                }
            }
        }
    `);

    return (
        <header className="z-20 bg-secondary dark:bg-gray-800">
            <div
                className={`${
                    isExpanded ? `h-auto` : `h-16 md:h-24`
                }  py-4 flex flex-wrap items-center justify-between max-w-6xl px-4 mx-auto`}
            >
                <Link to="/">
                    <div className="flex items-center">
                        <img
                            className="w-52"
                            src={theme === "dark" ? LogoLight : Logo}
                            alt={data.site.siteMetadata.title}
                        />

                        {/* <img
                            src={LogoEvent}
                            alt="Event Logo"
                            className="h-8 md:h-16 ml-4"
                        /> */}
                    </div>
                </Link>

                <button
                    className="items-center block h-auto px-3 py-2 rounded md:hidden"
                    onClick={() => toggleExpansion(!isExpanded)}
                >
                    <svg
                        className="w-6 h-6 text-gray-700 dark:text-gray-200 stroke-current"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>

                <nav
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } md:block md:items-center w-full md:w-auto`}
                >
                    <div className="hidden hoverable dark:text-gray-400 dark:hover:text-gray-100 mt-4 no-underline md:inline-block md:mt-0 md:ml-6">
                        <Link className="hoverabledark:text-gray-400 dark:hover:text-gray-100 relative block text-xl md:text-base font-sans cursor-pointer">
                            {data.contentfulMenuTop.megaMenu.title}
                        </Link>
                        <div className="mega-menu">
                            <div className="p-0 md:p-10 mt-6 mb-16 sm:mb-0 shadow-lg bg-white dark:bg-gray-700 max-w-6xl mx-auto rounded-lg">
                                <div className="flex flex-wrap pb-4 md:pb-0">
                                    {data.contentfulMenuTop.megaMenu.content.map(
                                        (item) => (
                                            <Link
                                                className="menu_top menu_top_mega w-1/2 md:w-1/3 flex flex-col hover:bg-primary dark:hover:bg-gray-600 hover:text-white dark:text-gray-400 dark:hover:text-gray-100 p-6 rounded-lg cursor-pointer mb-2 md:mb-6"
                                                to={item.url}
                                                key={item.title}
                                            >
                                                <div className="w-16 h-16 mb-3">
                                                    <img
                                                        className="h-14 max-w-none"
                                                        src={`../icons/${item.icon}`}
                                                        alt=""
                                                    />
                                                </div>

                                                <p className="font-display md:text-xl mb-0 md:mb-3 leading-tight dark:text-gray-200">
                                                    {item.title}
                                                </p>
                                                <p className="hidden md:block text-sm">
                                                    {item.text}
                                                </p>
                                            </Link>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {data.contentfulMenuTop.menu.map((link) => (
                        <Link
                            className="menu_top hidden md:inline-block text-xl md:text-base text-gray-700 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 transition-all ease-in duration-75 no-underline md:mt-0 md:ml-6 font-sans"
                            key={link.title}
                            to={link.route}
                            target={link.isInternal === false ? `_blank` : null}
                            rel={
                                link.isInternal === false ? "noreferrer" : null
                            }
                        >
                            {link.title} {link.isInternal}
                        </Link>
                    ))}

                    <div className="inline-block ml-6">
                        <div className="flex flex-row space-x-3">
                            <div className="hidden md:flex">
                                {data.contentfulMenuTop.cta.map((btn) => (
                                    <Button
                                        key={btn.title}
                                        text={btn.title}
                                        url={btn.route}
                                        color={btn.type}
                                        size="md"
                                        className="menu_top md:ml-4 md:mr-0 font-sans"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="block md:hidden my-4">
                        <Link
                            className="menu_top block text-xl md:text-base text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-100 transition-all ease-in duration-75no-underline md:mt-0 md:ml-6 font-sans"
                            to="#"
                        >
                            {data.contentfulMenuTop.megaMenu.title}
                        </Link>

                        <div className="mb-3">
                            {data.contentfulMenuTop.megaMenu.content.map(
                                (item) => (
                                    <Link
                                        className="menu_top block ml-4 text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-100 transition-all ease-in duration-75 no-underline leading-loose font-sans"
                                        to={item.url}
                                        key={item.title}
                                    >
                                        {item.title}
                                    </Link>
                                )
                            )}
                        </div>

                        {data.contentfulMenuTop.menu.map((link) => (
                            <Link
                                className="menu_top block text-xl md:text-base text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-100 no-underline md:mt-0 md:ml-6 font-sans"
                                key={link.title}
                                to={link.route}
                                target={
                                    link.isInternal === false ? "_blank" : null
                                }
                                rel={
                                    link.isInternal === false
                                        ? "noreferrer"
                                        : null
                                }
                            >
                                {link.title}
                            </Link>
                        ))}

                        <div className="mt-4">
                            {data.contentfulMenuTop.cta.map((btn) => (
                                <Button
                                    key={btn.title}
                                    text={btn.title}
                                    url={btn.route}
                                    color={btn.type}
                                    size="md"
                                    className="menu_top md:ml-4 md:mr-0 font-sans"
                                />
                            ))}
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
